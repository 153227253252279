<template>
    <div class="DeptEdit">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>

        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" :model="dept" label-width="110px" :rules="rules" size="small">
                <el-form-item label="名称" prop="name">
                    <el-input v-model.trim="dept.name" />
                </el-form-item>
                <el-form-item
                    label="营业执照名称"
                    prop="deptLicenseName"
                    v-if="dept.type === deptTypeEnum.STORE && dept.shopType === 0"
                >
                    <el-input v-model.trim="dept.deptLicenseName" prop="deptLicenseName" />
                </el-form-item>
                <el-form-item label="类型">
                    <el-radio-group v-model.number="dept.type" v-if="dept.type !== 3">
                        <el-radio :label="dept.type">{{ deptTypeEnum.properties[dept.type].desc }}</el-radio>
                    </el-radio-group>
                    <el-radio-group v-model.number="dept.shopType" v-if="dept.type === 3" disabled>
                        <el-radio :label="0">{{ deptTypeEnum.properties[dept.type].desc }}</el-radio>
                        <el-radio :label="1">仓库</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="所属机构组:" style="white-space: nowrap">
                    {{ this.$route.params.deptGroupName }}
                </el-form-item>
                <el-form-item label="行政区域">
                    <ef-cascade-area @ok="handleSelectArea" v-model="metaArea" />
                </el-form-item>
                <p style="margin-left: 80px; font-size: 13px">
                    如门店在朗勤心选系统开通自提业务，修改店铺行政区域后请联系八号地更改自提点地址，原地址将失效处理。
                </p>
                <el-form-item label="详细地址" prop="address">
                    <el-input v-model.trim="dept.address" />
                </el-form-item>
                <el-form-item
                    v-if="deptTypeEnum.properties[dept.type].desc === '门店' && dept.shopType === 0"
                    label="免费期限"
                    prop="freeYears"
                >
                    <el-input
                        v-model.trim="dept.freeYears"
                        style="width: 50px"
                        :disabled="true"
                        oninput="value=value.replace(/[^\d]/g,'')"
                    />
                    <span> 年</span>
                </el-form-item>
                <el-form-item
                    v-if="deptTypeEnum.properties[dept.type].desc === '门店' && dept.shopType === 0"
                    label="合同日期"
                    prop="freeYears"
                >
                    <el-date-picker
                        :disabled="true"
                        value-format="timestamp"
                        v-model.trim="dept.contractTime"
                        placeholder="选择日期"
                        :picker-options="pickerOptions"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSave" size="small">保存</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import EfCascadeArea from '../../../components/EfCascadeArea';
import UrlUtils from '../../../../js/UrlUtils';
import { DeptTypeEnum } from 'js/DeptCommon';

export default {
    name: 'DeptEdit',
    data() {
        return {
            pickerOptions: {
                disabledDate: (time) => {
                    //不能大于当前日期
                    return time.getTime() > Date.now();
                },
            },
            dept: {
                type: 3,
                shopType: 0,
            },
            metaArea: [],
            metaLinkMen: [],
            deptTypeEnum: DeptTypeEnum,
            url: {
                relatedStaff: '/system/staff/relatedStaff',
                patch: '/system/dept/patch_c/',
            },
            rules: {
                address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
                name: [{ required: true, message: '请输入部门名称', trigger: 'blur' }],
            },
        };
    },
    components: { EfCascadeArea },
    props: ['form'],
    mounted() {
        const _this = this;
        this.dept = JSON.parse(JSON.stringify(this.form));
        this.metaArea = [this.dept.provinceCode, this.dept.cityCode, this.dept.districtCode];
        UrlUtils.QueryRemote(this, this.url.relatedStaff + '?deptCode=' + this.dept.code, (rst) => {
            _this.metaLinkMen = rst;
        });

        var testTime = new Date(_this.dept.contractTime);
        _this.dept.contractTime = testTime.getTime();
    },
    methods: {
        handleSelectArea(areas) {
            this.dept.provinceCode = areas[0];
            this.dept.cityCode = areas[1];
            this.dept.districtCode = areas[2];
        },
        handleSave() {
            const _params = {
                type: this.dept.type,
                code: this.dept.code,
                name: this.dept.name,
                deptLicenseName: this.dept.deptLicenseName,
                linkmanCode: this.dept.linkmanCode,
                provinceCode: this.dept.provinceCode,
                cityCode: this.dept.cityCode,
                districtCode: this.dept.districtCode,
                address: this.dept.address,
                freeYears: this.dept.freeYears,
                contractTime: this.dept.contractTime,
            };
            if (!_params.name) {
                this.$alert('请填写名称', '提示', { type: 'warning' });
                return;
            }
            if (
                this.deptTypeEnum.properties[this.dept.type].desc === '门店' &&
                !_params.deptLicenseName &&
                this.dept.shopType === 0
            ) {
                this.$alert('请填写营业执照名称', '提示', { type: 'warning' });
                return;
            }
            if (!_params.address) {
                this.$alert('请填写详细地址', '提示', { type: 'warning' });
                return;
            }
            if (this.dept.shopType === 1) {
                delete _params.contractTime;
            }
            UrlUtils.PatchRemote(this, this.url.patch + this.dept.code, _params, null, (res) => {
                this.goBackAndReload();
                this.$message.success('保存成功');
            });
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
